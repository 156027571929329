

















import Vue from 'vue';
import { mdiCheck } from '@mdi/js';

export default Vue.extend({
  name: 'ChecklistItem',
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
      default: '',
    },
    iconColor: {
      type: String,
      required: false,
      default: 'text-white',
    },
    textColor: {
      type: String,
      required: false,
      default: 'text-white',
    },
    titleVariant: {
      type: String,
      required: false,
      default: 'subheading',
    },
    subtitleVariant: {
      type: String,
      required: false,
      default: 'body',
    },
    marginBeforeText: {
      type: String,
      required: false,
      default: 'ml-12',
    },
  },
  data() {
    return {
      checkIcon: mdiCheck,
    };
  },
});
