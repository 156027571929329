






import Vue from 'vue';
export default Vue.extend({
  name: 'Checklist',
  props: {
    space: {
      type: Number,
      required: false,
      default: 24,
    },
  },
  computed: {
    spaceY(): string {
      return 'space-y-' + String(this.space);
    },
  },
});
