





































































import Vue from 'vue';
import { ClientAuthService } from '~/src/core/_/auth/services/clientAuthService';
import TheLoginSideList from '~/components/login/TheLoginSideList.vue';
import Divider from '~/components/common/Divider.vue';
import ChecklistItem from '~/components/common/ChecklistItem.vue';
import Checklist from '~/components/common/Checklist.vue';
import { SITE_TITLE } from '~/src/meta/constants';

export default Vue.extend({
  components: {
    TheLoginSideList,
    Divider,
    ChecklistItem,
    Checklist,
  },
  layout: 'login',
  auth: 'guest',
  data() {
    return {
      title: 'Build your next Printful Project',
      listData: [
        {
          title: 'Create and share',
          subTitle: 'Build apps that empower Printful users to do more',
        },
        {
          title: 'Build and improve',
          subTitle:
            'Generate private tokens and build solutions for your company or private projects',
        },
        {
          title: 'Stay updated',
          subTitle: 'Read about new features and updates to Printful API',
        },
      ],
    };
  },
  head: {
    title: `Login | ${SITE_TITLE}`,
  },
  computed: {
    oauthLoginUrl(): string {
      return this.$container.get(ClientAuthService).getOauthLoginUrl();
    },
    oauthSignUpUrl(): string {
      return this.$container.get(ClientAuthService).getOauthSignUpUrl();
    },
  },
});
